.PagePartialsContainer {
    margin-top: 10px;
    margin-left: 110px;
    margin-right: 100px;
}

@media (max-width: 400px) {
    .PagePartialsContainer {
        margin-left: 70px;
        margin-right: 50px;
    }
}