.Album {
    float: left;
    height: auto;
    position: relative;
    font-size: .8em;
    -webkit-transition: -webkit-transform 0.4s ease-in-out, z-index 0.4s ease-in-out;
    -moz-transition: -moz-transform 0.4s ease-in-out, z-index 0.4s ease-in-out;
    -ms-transition: -ms-transform 0.4s ease-in-out, z-index 0.4s ease-in-out;
    -o-transition: -o-transform 0.4s ease-in-out, z-index 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, z-index 0.4s ease-in-out;
    -webkit-perspective: 600px;
    perspective: 600px;

    border: 5px solid #eee;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
}

.Album:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.8);
}

.Album img {
    width: 100%;
    height: auto;
}

.Album .front,
.Album .back {
    float: none;
    width: 100%;
    height: auto;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: -webkit-transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    -moz-transition: -moz-transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    -ms-transition: -ms-transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    -o-transition: -o-transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

p {
    margin-bottom: 5px;
}

.AlbumName {
    margin-top: 5px;
    font-size: 8px;
    font-weight: normal;
}

.AlbumArtist {
    font-size: 8px;
}

.AlbumTrackPlayCount {
    font-size: 8px;
}

.Album .front {
    z-index: 1;
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.Album .front .alpha {
    -webkit-transition: opacity 0.4s ease-in-out;
    -moz-transition: opacity 0.4s ease-in-out;
    -ms-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
    display: block;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.75);
    opacity: 0;
}

.Album .back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    text-align: center;
    font-family: sans-serif;
    padding: 10px;
    opacity: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

.Album.flip {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
    z-index: 10;
}

.Album.flip .front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.Album.flip .front .alpha {
    opacity: 1;
}

.Album.flip .back {
    z-index: 2;
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.Album {
    width: 50%;
    -webkit-text-size-adjust: none;
}

@media screen and (min-width: 900px) {
    .Album {
        width: 33%;
    }
    .AlbumName {
        margin-top: 10px;
        font-size: 12px;
        font-weight: bold;
    }

    .AlbumArtist {
        font-size: 12px;
    }

    .AlbumTrackPlayCount {
        font-size: 12px;
    }
}

@media screen and (min-width: 992px) {
    .Album {
        width: 25%;
    }
    .AlbumName {
        margin-top: 15px;
        font-size: 14px;
        font-weight: bold;
    }

    .AlbumArtist {
        font-size: 14px;
    }

    .AlbumTrackPlayCount {
        font-size: 14px;
    }
}

@media screen and (min-width: 1200px) {
    .Album {
        width: 20%;
    }
    .AlbumName {
        margin-top: 15px;
        font-size: 18px;
        font-weight: bold;
    }

    .AlbumArtist {
        font-size: 18px;
    }

    .AlbumTrackPlayCount {
        font-size: 18px;
    }
}